<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

// import CKEditor from "@ckeditor/ckeditor5-vue";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import Layout from "@/layouts/main.vue";
import PageHeader from "@/components/page-header.vue";
import EventServices from "@/services/EventServices";
import EventMessageVariablesPartial from "./partials/EventMessageVariablesPartial.vue";
import EventMembersPartial from "./partials/EventMembersPartial.vue";

import { mapGetters } from "vuex";

import SMSUtils from "@/helpers/SMSUtils";

export default {
  page: {
    title: "Lancer un événement",
  },
  components: {
    Layout,
    PageHeader,
    // ckeditor: CKEditor.component,
    EventMessageVariablesPartial,
    EventMembersPartial,
    FlatPickr: flatPickr,
  },
  data() {
    return {
      title: "Nouvel événement",
      items: [
        {
          text: "GICAM",
          href: "/",
        },
        {
          text: "Événement",
          href: "/",
        },
        {
          text: "Ajouter",
          active: true,
        },
      ],

      dateTimeConfig: {
        enableTime: true,
        dateFormat: "Y-m-d H:i",
      },

      submitting: false,

      // editor: ClassicEditor,

      fields: {},
      emailContent: `<p>Bonjour Madame, Monsieur,</p>
        <p>Dans le cadre de votre participation à xxxx du GICAM ce vendredi 02 Juin
          2023, recevez en pièce jointe votre PASS CODE que vous voudrez bien présenter dès votre arrivée à l'équipe en
          charge des enregistrements.</p>
        <p>Cordialement,</p>`,
      new_event: null,
    };
  },

  computed: {
    ...mapGetters("system", ["senders"]),
    firstCible() {
      return { nom: "Tchuimbou", entreprise: "Faroty" };
    },
    smsCount() {
      return SMSUtils.countLength(this.smsExemple ?? "");
    },
    smsExemple() {
      return this.fields.sms_content
        .trim()
        .replace(/[\r\n]/gm, "")
        .replace(/\[nom\]/gm, this.firstCible.nom)
        .replace(/\[entreprise\]/gm, this.firstCible.entreprise);
    },
    emailExemple() {
      const theContent = this.fields.email_content
        .replace(/\[nom\]/gm, this.firstCible.nom)
        .replace(/\[entreprise\]/gm, this.firstCible.entreprise)
        .replace(/(\r\n|\r|\n)/g, "<br />");

      return `<table cellpadding="0" cellspacing="0" width="100%" align="center" style="max-width: 600px; margin: 0 auto; margin-bottom: 0 !important">
    <tr>
      <td style="padding: 20px 0; text-align: center; background-color: #106454;">
        <h1 style="color: #ffffff;">${this.fields.email_title}</h1>
      </td>
    </tr>
    <tr>
      <td style="padding: 20px;">
        ${theContent}
      </td>
    </tr>
    <tr>
      <td style="padding: 20px; text-align: center; padding-top: 0px !important">
        <img src="/qr-code-sample.png" alt="QR Code" width="100" height="100" />
      </td>
    </tr>
  </table>`;
    },
  },

  mounted() {
    // user profile img file upload

    document.querySelectorAll(".form-steps").forEach(function (form) {
      // next tab
      form.querySelectorAll(".nexttab").forEach(function (nextButton) {
        var tabEl = form.querySelectorAll('button[data-bs-toggle="pill"]');
        tabEl.forEach(function (item) {
          item.addEventListener("show.bs.tab", function (event) {
            event.target.classList.add("done");
          });
        });
        nextButton.addEventListener("click", function () {
          var nextTab = nextButton.getAttribute("data-nexttab");
          document.getElementById(nextTab).click();
        });
      });

      //Pervies tab
      form.querySelectorAll(".previestab").forEach(function (prevButton) {
        prevButton.addEventListener("click", function () {
          var prevTab = prevButton.getAttribute("data-previous");
          var totalDone = prevButton
            .closest("form")
            .querySelectorAll(".custom-nav .done").length;
          for (var i = totalDone - 1; i < totalDone; i++) {
            const elem = prevButton
              .closest("form")
              .querySelectorAll(".custom-nav .done")[i];
            elem ? elem.classList.remove("done") : "";
          }
          document.getElementById(prevTab).click();
        });
      });

      // Step number click
      var tabButtons = form.querySelectorAll('button[data-bs-toggle="pill"]');
      tabButtons.forEach(function (button, i) {
        button.setAttribute("data-position", i);
        button.addEventListener("click", function () {
          var getProgreebar = button.getAttribute("data-progressbar");
          if (getProgreebar) {
            var totallength =
              document
                .getElementById("custom-progress-bar")
                .querySelectorAll("li").length - 1;
            var current = i;
            var percent = (current / totallength) * 100;
            document
              .getElementById("custom-progress-bar")
              .querySelector(".progress-bar").style.width = percent + "%";
          }
          form.querySelectorAll(".custom-nav .done").length > 0
            ? form
                .querySelectorAll(".custom-nav .done")
                .forEach(function (doneTab) {
                  doneTab.classList.remove("done");
                })
            : "";
          for (var j = 0; j <= i; j++) {
            tabButtons[j].classList.contains("active")
              ? tabButtons[j].classList.remove("done")
              : tabButtons[j].classList.add("done");
          }
        });
      });
    });
  },

  created() {
    this.initDatas();
  },
  methods: {
    initDatas() {
      this.fields = {
        members: [],

        name: null,
        event_start_date: null,
        event_end_date: null,
        description: "",

        sender_id: 1,

        sms_content:
          "Cher [nom], Veuillez trouver ci-joint votre QrCode pour l'événement. Cordialement, ",

        email_subject: null,
        email_title: "",
        email_content: `Bonjour Madame, Monsieur,\nDans le cadre de votre participation à xxxx du GICAM ce vendredi 02 Juin 2023, recevez en pièce jointe votre PASS CODE que vous voudrez bien présenter dès votre arrivée à l'équipe en charge des enregistrements.\n\nCordialement,`,
      };
    },
    async handleSubmit() {
      this.submitting = true;
      await EventServices.create({
        ...this.fields,
        event_start_date: this.fields.event_start_date,
        event_end_date: this.fields.event_end_date,
      })
        .then(({ data }) => {
          if (data.error === false) {
            this.new_event = data.data.event;
            this.initDatas();
            window.document.querySelector("#submit-button").click();
          }
        })
        .finally(() => {
          this.submitting = false;
        });
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="d-flex align-items-center mb-3">
        <button
          type="button"
          class="btn btn-light btn-xl"
          @click="$router.replace('/')"
        >
          <i class="mdi mdi-arrow-left"></i> Retour
        </button>
      </div>

      <!--end col-->
      <div class="col-xxl-12 mt-4">
        <b-card no-body>
          <b-card-header>
            <b-card-title class="mb-0">
              Informations de l'événement
            </b-card-title>
          </b-card-header>
          <b-card-body>
            <form action="#" class="form-steps" autocomplete="off">
              <div class="step-arrow-nav mb-4">
                <ul
                  class="nav nav-pills custom-nav nav-justified"
                  role="tablist"
                >
                  <li class="nav-item" role="presentation">
                    <b-button
                      variant="link"
                      class="nav-link active"
                      id="steparrow-gen-info-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#steparrow-gen-info"
                      type="button"
                      role="tab"
                      aria-controls="steparrow-gen-info"
                      aria-selected="true"
                    >
                      Général
                    </b-button>
                  </li>
                  <!-- <li class="nav-item" role="presentation">
                    <b-button
                      variant="link"
                      class="nav-link"
                      id="steparrow-description-info-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#steparrow-description-info"
                      type="button"
                      role="tab"
                      aria-controls="steparrow-description-info"
                      aria-selected="false"
                    >
                      Description
                    </b-button>
                  </li> -->
                  <li class="nav-item" role="presentation">
                    <b-button
                      variant="link"
                      class="nav-link"
                      id="steparrow-sms-info-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#steparrow-sms-info"
                      type="button"
                      role="tab"
                      aria-controls="steparrow-sms-info"
                      aria-selected="false"
                    >
                      Config. SMS
                    </b-button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <b-button
                      variant="link"
                      class="nav-link"
                      id="steparrow-email-info-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#steparrow-email-info"
                      type="button"
                      role="tab"
                      aria-controls="steparrow-email-info"
                      aria-selected="false"
                    >
                      Config. Email
                    </b-button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <b-button
                      variant="link"
                      class="nav-link"
                      id="steparrow-members-info-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#steparrow-members-info"
                      type="button"
                      role="tab"
                      aria-controls="steparrow-members-info"
                      aria-selected="false"
                    >
                      Participants
                    </b-button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <b-button
                      variant="link"
                      class="nav-link"
                      id="steparrow-finish-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#steparrow-finish"
                      type="button"
                      role="tab"
                      aria-controls="steparrow-finish"
                      aria-selected="false"
                    >
                      Terminé
                    </b-button>
                  </li>
                </ul>
              </div>

              <div class="tab-content">
                <div
                  class="tab-pane fade show active"
                  id="steparrow-gen-info"
                  role="tabpanel"
                  aria-labelledby="steparrow-gen-info-tab"
                >
                  <div>
                    <b-row>
                      <b-col lg="12">
                        <div class="mb-3">
                          <label
                            class="form-label"
                            for="steparrow-gen-info-email-input"
                          >
                            Intitulé
                          </label>
                          <input
                            v-model="fields.name"
                            type="text"
                            class="form-control"
                            id="steparrow-gen-info-email-input"
                            required
                          />
                        </div>
                      </b-col>
                      <b-col lg="6">
                        <div class="mb-3">
                          <label
                            class="form-label"
                            for="steparrow-gen-info-username-input"
                          >
                            Prévu du
                          </label>

                          <FlatPickr
                            v-model="fields.event_start_date"
                            id="des-info-date-input"
                            :config="dateTimeConfig"
                            class="form-control"
                          ></FlatPickr>
                          <!-- <input
                            v-model="fields.event_start_date"
                            type="datetime-local"
                            class="form-control"
                            id="steparrow-gen-info-username-input"
                            required
                          /> -->
                        </div>
                      </b-col>
                      <b-col lg="6">
                        <div class="mb-3">
                          <label
                            class="form-label"
                            for="steparrow-gen-info-username-input"
                          >
                            Au
                          </label>

                          <FlatPickr
                            v-model="fields.event_end_date"
                            id="des-info-date-input"
                            :config="dateTimeConfig"
                            class="form-control"
                          ></FlatPickr>
                          <!-- <input
                            v-model="fields.event_end_date"
                            type="datetime-local"
                            class="form-control"
                            id="steparrow-gen-info-username-input"
                            required
                          /> -->
                        </div>
                      </b-col>
                    </b-row>
                    <!-- <div>
                      <label
                        class="form-label"
                        for="des-info-description-input"
                      >
                        Description
                      </label>
                      <textarea
                        v-model="fields.description"
                        class="form-control"
                        id="des-info-description-input"
                        rows="5"
                        required
                      ></textarea>
                    </div> -->
                  </div>
                  <div class="d-flex align-items-start gap-3 mt-4">
                    <b-button
                      type="button"
                      variant="primary"
                      class="btn-label right ms-auto nexttab nexttab"
                      data-nexttab="steparrow-sms-info-tab"
                      ><i
                        class="ri-arrow-right-line label-icon align-middle fs-16 ms-2"
                      ></i>
                      Suivant
                    </b-button>
                  </div>
                </div>

                <div
                  class="tab-pane fade"
                  id="steparrow-sms-info"
                  role="tabpanel"
                  aria-labelledby="steparrow-sms-info-tab"
                >
                  <div>
                    <b-row>
                      <b-col lg="12">
                        <div class="mb-3">
                          <label
                            class="form-label"
                            for="des-sender_name-name-input"
                          >
                            Nom d'envoie
                          </label>
                          <select
                            class="form-select"
                            id="des-sender_name-name-input"
                            v-model="fields.sender_id"
                            required
                          >
                            <option
                              v-for="sender of senders"
                              :key="sender.id"
                              :value="sender.id"
                            >
                              {{ sender.name }}
                            </option>
                          </select>
                        </div>
                      </b-col>

                      <b-col lg="12">
                        <EventMessageVariablesPartial />

                        <b-row>
                          <b-col lg="6">
                            <div>
                              <div class="d-flex justify-content-between">
                                <label
                                  class="form-label"
                                  for="des-info-description-input"
                                >
                                  Contenu du SMS
                                </label>
                                <span class="text-warning">
                                  {{ smsCount.remaining }} ({{
                                    smsCount.messages
                                  }})
                                </span>
                              </div>
                              <textarea
                                v-model.trim="fields.sms_content"
                                class="form-control"
                                id="des-info-description-input"
                                rows="3"
                                required
                              ></textarea>
                            </div>
                          </b-col>
                          <b-col lg="6">
                            <div>
                              <label
                                class="form-label"
                                for="des-info-description-input"
                              >
                                Exemple d'affichage
                              </label>
                              <textarea
                                :value="smsExemple"
                                readonly
                                disabled
                                class="form-control"
                                id="des-info-description-input"
                                rows="3"
                                required
                              ></textarea>
                            </div>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                  </div>
                  <div class="d-flex align-items-start gap-3 mt-4">
                    <b-button
                      type="button"
                      variant="primary"
                      class="btn-label right ms-auto nexttab nexttab"
                      data-nexttab="steparrow-email-info-tab"
                      ><i
                        class="ri-arrow-right-line label-icon align-middle fs-16 ms-2"
                      ></i>
                      Suivant
                    </b-button>
                  </div>
                </div>

                <div
                  class="tab-pane fade"
                  id="steparrow-email-info"
                  role="tabpanel"
                  aria-labelledby="steparrow-email-info-tab"
                >
                  <div>
                    <b-row>
                      <b-col lg="6">
                        <div class="mb-3">
                          <label
                            class="form-label"
                            for="steparrow-gen-info-email-input"
                          >
                            Sujet
                          </label>
                          <input
                            v-model="fields.email_subject"
                            type="text"
                            class="form-control"
                            id="steparrow-gen-info-email-input"
                            required
                          />
                        </div>
                      </b-col>

                      <b-col lg="6">
                        <div class="mb-3">
                          <label
                            class="form-label"
                            for="steparrow-gen-info-email-input"
                          >
                            Titre
                          </label>
                          <input
                            v-model="fields.email_title"
                            type="text"
                            class="form-control"
                            id="steparrow-gen-info-email-input"
                            required
                          />
                        </div>
                      </b-col>

                      <div class="col-lg-12">
                        <div class="mb-3 mt-3">
                          <EventMessageVariablesPartial />

                          <b-row>
                            <b-col lg="6">
                              <label for="jobDescription" class="form-label">
                                Corps du message
                              </label>
                              <textarea
                                v-model="fields.email_content"
                                class="form-control"
                                id="des-info-description-input"
                                rows="5"
                                style="height: 93%"
                                required
                              ></textarea>
                              <!-- <ckeditor
                                @input="console.log($event)"
                                v-model="emailContent"
                                :editor="editor"
                              ></ckeditor> -->
                            </b-col>
                            <b-col lg="6">
                              <label for="jobDescription" class="form-label">
                                Exemple de rendu
                              </label>
                              <div
                                class="mt-3 p-4 border border-secondary"
                                v-html="emailExemple"
                              ></div>
                            </b-col>
                          </b-row>
                        </div>
                      </div>
                    </b-row>
                  </div>
                  <div class="d-flex align-items-start gap-3 mt-4">
                    <b-button
                      type="button"
                      variant="primary"
                      class="btn-label right ms-auto nexttab nexttab"
                      data-nexttab="steparrow-members-info-tab"
                      :disabled="submitting"
                    >
                      <i
                        class="ri-arrow-right-line label-icon align-middle fs-16 ms-2"
                      ></i>

                      <span
                        v-if="submitting"
                        class="spinner-border spinner-border-sm text-secondary"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      <span v-else> Suivant </span>
                    </b-button>
                  </div>
                </div>

                <div
                  class="tab-pane fade"
                  id="steparrow-members-info"
                  role="tabpanel"
                  aria-labelledby="steparrow-members-info-tab"
                >
                  <EventMembersPartial @files="this.fields.members = $event" />

                  <div class="d-flex align-items-start gap-3 mt-4">
                    <button
                      type="button"
                      id="submit-button"
                      class="d-none nexttab nexttab"
                      data-nexttab="steparrow-finish-tab"
                    ></button>
                    <b-button
                      type="button"
                      variant="primary"
                      class="btn-label right ms-auto"
                      @click.prevent="handleSubmit"
                      :disabled="submitting"
                    >
                      <i
                        class="ri-arrow-right-line label-icon align-middle fs-16 ms-2"
                      ></i>

                      <span
                        v-if="submitting"
                        class="spinner-border spinner-border-sm text-secondary"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      <span v-else> Enregistrer </span>
                    </b-button>
                  </div>
                </div>

                <div
                  class="tab-pane fade"
                  id="steparrow-finish"
                  role="tabpanel"
                >
                  <div class="text-center">
                    <div class="avatar-md mt-5 mb-4 mx-auto">
                      <div
                        class="avatar-title bg-light text-success display-4 rounded-circle"
                      >
                        <i class="ri-checkbox-circle-fill"></i>
                      </div>
                    </div>
                    <h5>Parfait !</h5>
                    <p class="text-muted">
                      Votre événement a été créé. Vous pouvez maintenant le
                      gérer.
                    </p>
                    <b-button
                      type="button"
                      variant="primary"
                      class="mt-4 btn-label right ms-auto"
                      @click.prevent="
                        $router.replace({
                          name: 'campagnes-events-details',
                          params: { eventId: new_event.id },
                        })
                      "
                    >
                      <i
                        class="ri-arrow-right-line label-icon align-middle fs-16 ms-2"
                      ></i>
                      Gérer mon événement
                    </b-button>
                  </div>
                </div>
              </div>
            </form>
          </b-card-body>
        </b-card>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </Layout>
</template>
